import { apiClient } from 'api/apiClient/apiClient'
import { useMutation, useQuery } from 'react-query'
import authStore from 'store/auth/auth'
import uiConfigStore, { addUiConfig, addUiConfigs, removeUiConfigs } from 'store/uiConfig/uiConfig'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { getDefaultProps } from 'ui/uiConfig/factory'
import bugsnag from 'utils/bugsnag/bugsnag'
import { snapshot, useSnapshot } from 'valtio'

import { clone } from 'helpers/global.helper/global.helper'
import { queryClient } from 'helpers/queryClient'

type UiConfigOptions = {
  mode?: `beta` | `dev` | null
}
type UiConfigParams = {
  system?: number
  component: string
  mode?: `beta` | `dev`
}
export const UI_CONFIG_QUERY_KEY = `uiConfig`
export function useUiConfig(systemId: number | undefined | null, component: string, options?: UiConfigOptions) {
  const params: UiConfigParams = { system: systemId, component }

  if (options?.mode) {
    params.mode = options?.mode
  }

  return useQuery([UI_CONFIG_QUERY_KEY, params], async () => {
    if (systemId === undefined || systemId === null) {
      return []
    }
    const uiConfigs = await apiClient<UiConfig[]>(`ui_config`, { params })

    // [2024-09-17] Mattias: There is a race-condition here for template UiConfigs stored in the database. On initial load, templates from React codebase is used, but on system-change templates from the database is used. The templates in the database (denoted with negative IDs) are not needed any more and should be removed. This is a temporary fix to filter out templates from the database that arrives from this API.
    addUiConfigs(uiConfigs
      .filter(uiConfig => uiConfig.id > 0)
    )

    return uiConfigs
  })
}

export function useUiConfigAnchorComponent(type: string, systemId?: number) {
  const authSnap = useSnapshot(authStore)
  const { systemId: globalSystemId } = useAuth()
  const uiConfigRes = useUiConfig(systemId || globalSystemId, type, { mode: authSnap.isBeta ? `beta` : null })

  return useQuery([UI_CONFIG_QUERY_KEY, uiConfigRes], async () => {
    const unparsedUiConfig = uiConfigRes?.data?.find((u) => u.component === type)

    if (!unparsedUiConfig) {
      return null
    }

    const uiConfigSnap = snapshot(uiConfigStore)
    const uiConfig = uiConfigSnap.getParsedUiConfig(unparsedUiConfig.uid)

    return uiConfig
  })
}

export const UI_CONFIG_FOR_MULTIPLE_SYSTEMS_QUERY_KEY = `uiConfig`
export function useUiConfigForMultipleSystems(systemIds: number[], component: string, options?: UiConfigOptions) {
  return useQuery([UI_CONFIG_QUERY_KEY, systemIds, component], async () => {
    if (!systemIds.length) {
      return []
    }
    let uiConfigs: UiConfig[] = []

    for (const systemId of systemIds) {
      const params: UiConfigParams = { system: systemId, component }

      if (options?.mode) {
        params.mode = options?.mode
      }

      const systemUiConfigs = await apiClient<UiConfig[]>(`ui_config`, { params })
      uiConfigs = uiConfigs.concat(systemUiConfigs)
    }

    addUiConfigs(uiConfigs)
    return uiConfigs
  })
}

export const UI_CONFIG_BY_ID_QUERY_KEY = `uiConfigById`
export function useUiConfigById(id: number | null) {
  return useQuery([UI_CONFIG_BY_ID_QUERY_KEY, id], async () => {
    if (!id) {
      return []
    }

    const uiConfigs = await apiClient<UiConfig[]>(`ui_config`, { params: { id } })

    addUiConfigs(uiConfigs)
    return uiConfigs
  })
}

export const ALL_SYSTEM_UI_CONFIG_QUERY_KEY = `allUiConfig`
export function useAllSystemUiConfigs(options?: UiConfigOptions) {
  const { systemId: system } = useAuth()
  const params: { system?: number; mode?: `beta` | `dev` } = { system }

  if (options?.mode) {
    params.mode = options?.mode
  }

  return useQuery([ALL_SYSTEM_UI_CONFIG_QUERY_KEY, system], async () => {
    const uiConfigs = await apiClient<UiConfig[]>(`ui_config`, { params })

    addUiConfigs(uiConfigs)

    return uiConfigs
  })
}

export const UI_CONFIG_VERSIONS = `uiConfigVersions`
export function useUiConfigVersions(uid: UiConfigUid | null) {
  return useQuery([UI_CONFIG_VERSIONS, uid], async () => {
    if (!uid) {
      return []
    }

    const limit = 100
    const params = { limit }
    const uiConfigs = await apiClient<UiConfig[]>(`ui_config/${uid}/versions`, { params })

    addUiConfigs(uiConfigs)

    return uiConfigs
  })
}

export function useUpdateUiConfigMutation() {
  const { error: errorAlert } = useAlert()

  return useMutation(
    (uiConfig: UiConfig) => {
      const updateUiConfig: {
        version?: number
        children_ids?: number[]
      } & Omit<UiConfig, `version` | `children_ids`> = clone(uiConfig)

      delete updateUiConfig.version

      if (!updateUiConfig?.children_ids?.length) {
        delete updateUiConfig.children_ids
      }

      return apiClient<UiConfig>(`ui_config`, {
        data: updateUiConfig,
      })
    },
    {
      onMutate: async (uiConfig: UiConfig) => {
        await queryClient.cancelQueries(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        await queryClient.cancelQueries(UI_CONFIG_VERSIONS)

        const previousAllSystemsUiConfig = queryClient.getQueryData<UiConfigCollection>(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        const previousUiConfigVersions = queryClient.getQueryData<UiConfigCollection>(UI_CONFIG_VERSIONS)

        if (previousAllSystemsUiConfig) {
          queryClient.setQueryData(ALL_SYSTEM_UI_CONFIG_QUERY_KEY, {
            ...previousAllSystemsUiConfig,
            [uiConfig.uid]: uiConfig,
          })
        }

        if (previousUiConfigVersions) {
          queryClient.setQueryData(UI_CONFIG_VERSIONS, {
            ...previousUiConfigVersions,
            [uiConfig.id]: [uiConfig.uid],
          })
        }

        return { previousAllSystemsUiConfig, previousUiConfigVersions }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        queryClient.invalidateQueries(UI_CONFIG_VERSIONS)
      },
      onSettled: (responseUiConfig?: UiConfig) => {
        if (!responseUiConfig) {
          return
        }

        addUiConfig(responseUiConfig)
      },
      onError: (error: Error, _variables, rollback) => {
        if (error) {
          errorAlert('Error found in config: ' + JSON.stringify(error))
        }

        bugsnag.notify(error)

        if (!rollback) {
          return
        }

        queryClient.setQueryData(ALL_SYSTEM_UI_CONFIG_QUERY_KEY, rollback.previousAllSystemsUiConfig)
        queryClient.setQueryData(UI_CONFIG_VERSIONS, rollback.previousUiConfigVersions)
      },
    }
  )
}

export function useUnpublishUiConfigMutation() {
  return useMutation(
    (uid: UiConfigUid) => {
      return apiClient(`ui_config/${uid}/unpublish`, { method: `POST` })
    },
    {
      onMutate: async (uid: UiConfigUid) => {
        await queryClient.cancelQueries(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        await queryClient.cancelQueries(UI_CONFIG_VERSIONS)

        const previousAllSystemsUiConfig = queryClient.getQueryData<UiConfigCollection>(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        const previousUiConfigVersions = queryClient.getQueryData<UiConfigCollection>(UI_CONFIG_VERSIONS)

        if (previousAllSystemsUiConfig) {
          delete previousAllSystemsUiConfig[uid]
          queryClient.setQueryData(ALL_SYSTEM_UI_CONFIG_QUERY_KEY, previousAllSystemsUiConfig)
        }
        if (previousUiConfigVersions) {
          delete previousUiConfigVersions[uid]
          queryClient.setQueryData(UI_CONFIG_VERSIONS, previousUiConfigVersions)
        }

        return { previousAllSystemsUiConfig, previousUiConfigVersions }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        queryClient.invalidateQueries(UI_CONFIG_VERSIONS)
      },
      onError: (error: Error, _variables, rollback) => {
        bugsnag.notify(error)

        if (!rollback) {
          return
        }

        queryClient.setQueryData(ALL_SYSTEM_UI_CONFIG_QUERY_KEY, rollback.previousAllSystemsUiConfig)
        queryClient.setQueryData(UI_CONFIG_VERSIONS, rollback.previousUiConfigVersions)
      },
    }
  )
}

export function useDeleteUiConfigMutation() {
  return useMutation(
    (id: UiConfigId) => {
      return apiClient(`ui_config/${id}/delete`, { method: `DELETE` })
    },
    {
      onMutate: async (id: UiConfigId) => {
        await queryClient.cancelQueries(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        await queryClient.cancelQueries(UI_CONFIG_VERSIONS)


        const previousAllSystemsUiConfig = queryClient.getQueryData<UiConfigCollection>(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        const previousUiConfigVersions = queryClient.getQueryData<UiConfigCollection>(UI_CONFIG_VERSIONS)

        if (previousAllSystemsUiConfig) {
          Object.entries(previousAllSystemsUiConfig).forEach(([uid, uiConfig]) => {
            if (uiConfig.id === id) {
              delete previousAllSystemsUiConfig[uid]
            }
          })

          queryClient.setQueryData(ALL_SYSTEM_UI_CONFIG_QUERY_KEY, previousAllSystemsUiConfig)
        }
        if (previousUiConfigVersions) {
          Object.entries(previousUiConfigVersions).forEach(([uid, uiConfig]) => {
            if (uiConfig.id === id) {
              delete previousUiConfigVersions[uid]
            }
          })

          queryClient.setQueryData(UI_CONFIG_VERSIONS, previousUiConfigVersions)
        }

        removeUiConfigs(id)

        return { previousAllSystemsUiConfig, previousUiConfigVersions }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        queryClient.invalidateQueries(UI_CONFIG_VERSIONS)
      },
      onError: (error: Error, _variables, rollback) => {
        bugsnag.notify(error)

        if (!rollback) {
          return
        }

        queryClient.setQueryData(ALL_SYSTEM_UI_CONFIG_QUERY_KEY, rollback.previousAllSystemsUiConfig)
        queryClient.setQueryData(UI_CONFIG_VERSIONS, rollback.previousUiConfigVersions)
      },
    }
  )
}

export function useCreateUiConfigMutation() {
  const { systemId: system } = useAuth()

  return useMutation(
    ({ component, defaultData }: { component: string; defaultData?: UiConfig }) => {
      const props = getDefaultProps(component)

      if (defaultData) {
        delete defaultData.id
        delete defaultData?.uid
      }

      return apiClient<UiConfig>(`ui_config`, {
        method: `POST`,
        data: { system, component, props, ...(defaultData || {}) },
      })
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)

        const previousResponse = queryClient.getQueryData<UiConfigCollection>(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)

        if (previousResponse) {
          queryClient.setQueryData(ALL_SYSTEM_UI_CONFIG_QUERY_KEY, previousResponse)
        }

        return { previousResponse }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ALL_SYSTEM_UI_CONFIG_QUERY_KEY)
        queryClient.invalidateQueries(UI_CONFIG_VERSIONS)
      },
      onSettled: (responseUiConfig?: UiConfig) => {
        if (!responseUiConfig) {
          return
        }

        addUiConfig(responseUiConfig)
      },
      onError: (error: Error, _variables, rollback) => {
        bugsnag.notify(error)

        if (!rollback) {
          return
        }

        queryClient.setQueryData(ALL_SYSTEM_UI_CONFIG_QUERY_KEY, rollback.previousResponse)
      },
    }
  )
}
