import React, { ReactElement, useCallback, useContext, useMemo, useState } from 'react'

import { ACTIVE_SYSTEM_LOCAL_STORAGE_KEY, setCurrency } from 'store/auth/auth'

type AuthContextType = {
  activeSystem: System | null
  user: User | null
  setActiveSystem: (system: System) => void
  setUser: (user: User | null) => void
  systemId?: number
}

export const AuthContext = React.createContext<AuthContextType | undefined>(undefined)

const userLocalStorageKey = `user`

type AuthProviderProps = { children: React.ReactNode }

export function AuthProvider({ children }: AuthProviderProps): ReactElement {
  const [activeSystem, setActiveSystemLocal] = useState(() => {
    const activeSystemItem = localStorage.getItem(ACTIVE_SYSTEM_LOCAL_STORAGE_KEY)
    const activeSystem: System | null = activeSystemItem ? JSON.parse(activeSystemItem) : null
    return activeSystem
  })

  const [user, setUserLocal] = useState(() => {
    const userItem = localStorage.getItem(userLocalStorageKey)
    const user: User | null = userItem ? JSON.parse(userItem) : null
    return user
  })

  const setActiveSystem = useCallback((system: System) => {
    localStorage.setItem(ACTIVE_SYSTEM_LOCAL_STORAGE_KEY, JSON.stringify(system))
    setActiveSystemLocal(system)
    setCurrency(system.currency)
  }, [])

  const setUser = useCallback((user: User | null) => {
    user && localStorage.setItem(userLocalStorageKey, JSON.stringify(user))
    setUserLocal(user)
  }, [])

  const systemId = activeSystem?.id ?? user?.system

  const value = useMemo(
    () => ({ activeSystem, setActiveSystem, user, setUser, systemId }),
    [activeSystem, setActiveSystem, user, setUser, systemId]
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within \`AuthProvider\``)
  }
  return context
}
