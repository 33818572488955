import { useRef, useEffect } from 'react'

import { BulletinObjectProperty, BulletinSetting } from 'api/bulletinBoard/bulletinBoard.api'
import { ObjectProperty, ValueMapping } from 'api/objectProperties/objectProperties.api'
import { Setting } from 'api/settings/settings.api'
import i18n from 'i18next'
import moment, { Moment } from 'moment'
import authStore, { getCurrency as getCurrencyFromStore } from 'store/auth/auth'
import { editingUiConfigStore } from 'store/uiConfig/uiConfig'
import Datetime from 'utils/datetime/datetime'
import { useSnapshot } from 'valtio'

import { getWindowLocale, localizationMapping } from 'localization/localization.helper'

export function getDisplayName(object: DisplayNameObject): string {
  return i18n.t(object.display_name ?? object.name ?? '')
}
type LanguageCode = `en` | `sv` | `fi`
export function getTranslatedProperty<
  Key extends string,
  ObjectType extends Record<Key | `${Key}_${LanguageCode}`, string | null>,
>(object: ObjectType, property_name: Key): string | null {
  const locale = getWindowLocale()
  const localeValue = localizationMapping(locale)
  return object[`${property_name}_${localeValue}` as const] ?? object[property_name]
}

export function getFirstOccuringSetting(
  activeSettings: Setting[],
  objectProperty: ObjectProperty,
  followUpEndTime?: ISODateTime,
  overrideNow?: ISODateTime
): Setting | undefined {
  const utcNow = overrideNow || Datetime.getISONow()

  const settingsToCheck: Setting[] = activeSettings
    .filter((setting) => {
      if (!setting.start_time) {
        return false
      }

      const settingEndTime = setting.end_time && Datetime.toISOString(setting.end_time)
      const settingStartTime = Datetime.toISOString(setting.start_time)

      if (Datetime.isAfterOrEqual(settingStartTime, utcNow)) {
        return true
      }

      if (Datetime.isBeforeOrEqual(settingStartTime, utcNow)) {
        if (!setting.end_time) {
          return true
        }

        if (settingEndTime && Datetime.isAfterOrEqual(settingEndTime, utcNow)) {
          return true
        }
      }

      if (followUpEndTime && settingEndTime) {
        if (Datetime.isBeforeOrEqual(settingEndTime, followUpEndTime)) {
          return true
        }
      }

      return false
    })
    .sort((a, b) => {
      const startTimeA = a.start_time ? Datetime.toISOString(a.start_time) : ''
      const startTimeB = b.start_time ? Datetime.toISOString(b.start_time) : ''
      return startTimeA.localeCompare(startTimeB)
    })

  let firstOccuringSetting = settingsToCheck.find((s) => s.object_property === objectProperty.id)

  if (!firstOccuringSetting) {
    return undefined
  }

  // Should not return setting if it's in the future
  if (Datetime.isAfter(Datetime.toISOString(firstOccuringSetting.start_time), utcNow)) {
    return firstOccuringSetting
  }

  // If setting is in the future, we need to check if it's the first occuring setting
  let isInFuture = firstOccuringSetting.end_time === null
  isInFuture = isInFuture || Datetime.isAfter(Datetime.toISOString(firstOccuringSetting.end_time), utcNow)

  if (isInFuture) {
    firstOccuringSetting = settingsToCheck
      .sort((a, b) => {
        const updatedAtA = a.updated_at || ''
        const updatedAtB = b.updated_at || ''
        return updatedAtB.localeCompare(updatedAtA)
      })
      .find((s) => {
        const hasCorrectOPID = s.object_property === objectProperty.id
        const hasCorrectStartTime = Datetime.isBeforeOrEqual(Datetime.toISOString(s.start_time), utcNow)
        let hasCorrectEndTime = false
        hasCorrectEndTime = hasCorrectEndTime || s.end_time === null
        hasCorrectEndTime = hasCorrectEndTime || Datetime.isAfterOrEqual(Datetime.toISOString(s.end_time), utcNow)

        return hasCorrectOPID && hasCorrectStartTime && hasCorrectEndTime
      })
  }
  return firstOccuringSetting
}

export function getSettingValue(settingValue: number | string): string | number {
  return settingValue === 1 || settingValue === 0 ? i18n.t(`Active`) : settingValue
}

export type ValueObject = {
  unit: string
  value: string
  valueSize: string
  realValue: number | null
}

export function createCostObject(value: number, decimals?: number, unitEnding = `SEK`): ValueObject {
  const realValue = value
  let valueSize = ``
  let fixedVal = ``
  if (Math.abs(value) > 999 && Math.abs(value) < 999999) {
    value = value / 1000
    fixedVal = toDecimals(value, decimals)
    valueSize = `k`
  } else if (Math.abs(value) > 999999) {
    value = value / 1000000
    fixedVal = toDecimals(value, decimals)
    valueSize = `M`
  } else if (Math.abs(value) > 0 && Math.abs(value) < 999) {
    fixedVal = toDecimals(value, decimals, 1)
  } else {
    fixedVal = toDecimals(value, decimals, 1)
  }

  return {
    unit: valueSize + unitEnding,
    value: getRoundedValue(fixedVal),
    valueSize: valueSize,
    realValue: realValue,
  }
}

export function createEnergyObject(realValue: number, decimals?: number): ValueObject {
  let valueSize = `M`
  let value = realValue
  if (Math.abs(realValue) > 999) {
    value = realValue / 1000
    valueSize = `G`
  }

  return {
    unit: valueSize + `Wh`,
    value: getRoundedValue(toDecimals(value, decimals)),
    valueSize: valueSize,
    realValue: realValue,
  }
}

export function getRoundedValue(fixedValue: string): string {
  let roundedValue = fixedValue

  if (Math.abs(parseFloat(roundedValue)) < 0.0001) {
    roundedValue = '0'
  }

  return roundedValue
}

export function convertNumberDecimalSign(value: number): number {
  if (!value) {
    return value
  }

  const valueAsString = value.toString()
  return parseFloat(valueAsString.replace(',', '.'))
}

export function createPowerObject(realValue: number, decimals?: number): ValueObject {
  let valueSize = `M`
  let value = realValue
  if (Math.abs(realValue) > 999) {
    value = realValue / 1000
    valueSize = `G`
  }

  return {
    unit: valueSize + `W`,
    value: getRoundedValue(toDecimals(value, decimals)),
    valueSize: valueSize,
    realValue: realValue,
  }
}

export function createTemperatureObject(realValue: number, decimals?: number): ValueObject {
  return {
    unit: `°C`,
    value: getRoundedValue(toDecimals(realValue, decimals)),
    valueSize: '',
    realValue: realValue,
  }
}

export function useCurrency(): Currency {
  const activeSystem = useSnapshot(authStore).activeSystem
  const currency: Currency = activeSystem?.currency || 'SEK'
  return currency
}

export function getCurrency(): Currency {
  return getCurrencyFromStore()
}

export function getTypeObject(
  value: number | null | undefined,
  type?: string,
  unit?: string,
  decimals?: number
): ValueObject {
  const currency = getCurrency()

  if (value !== undefined && value !== null) {
    if (type === `energy`) {
      return createEnergyObject(value, decimals)
    }
    if (type === `power`) {
      return createPowerObject(value, decimals)
    }
    if (type === `cost`) {
      return createCostObject(value, decimals, currency)
    }
    if (type === 'cost/energy') {
      return createCostObject(value, decimals, `${currency}/MWh`)
    }
    if (type === 'temperature') {
      return createTemperatureObject(value, decimals)
    }

    const fixedValue = toDecimals(value, decimals)

    return {
      unit: unit ? unit : ``,
      value: getRoundedValue(fixedValue),
      valueSize: ``,
      realValue: value,
    }
  } else {
    return {
      unit: ``,
      value: `-`,
      valueSize: ``,
      realValue: value ?? 0,
    }
  }
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function prettyPrintAnySettingValue(
  prop: ObjectProperty | BulletinObjectProperty,
  setting: Setting | BulletinSetting
): string | number {
  const valueString =
    prop.name?.includes(`forced`) || prop.name?.includes(`availability`) ? i18n.t(`Active`) : setting.value
  return valueString
}

export function hasLowerCase(str: string): boolean {
  return /[a-z]/.test(str)
}

export function hasUpperCase(str: string): boolean {
  return /[A-Z]/.test(str)
}

export function hasSpecialChar(str: string): boolean {
  return /[^a-zA-Z\d]/.test(str)
}

export function validatePasswordPolicyRequirements(pass: string): boolean {
  return ((hasLowerCase(pass) && hasUpperCase(pass)) || hasSpecialChar(pass)) && pass.length >= 8
}

export function getDisplayDate(date: Moment): string {
  if (date.format(`ddd D MMMM`) === moment().format(`ddd D MMMM`)) {
    return i18n.t(`Today `)
  } else if (date.format(`ddd D MMMM`) === moment().add(1, `d`).format(`ddd D MMMM`)) {
    return i18n.t(`Tomorrow`)
  } else if (date.format(`ddd D MMMM`) === moment().subtract(1, `d`).format(`ddd D MMMM`)) {
    return i18n.t(`Yesterday `)
  } else return ``
}

export function getTextFromValue(value: number, value_mappings: ValueMapping[]): string | undefined {
  const mapping = value_mappings.find((m) => m.value === value)

  if (!mapping) return undefined
  const locale = getWindowLocale()
  const localeText = localizationMapping(locale)
  return mapping[`text_${localeText}` as `text_sv` | `text_en`]
}

export const startTimeLimit = moment().year(2020).startOf(`year`)

export function isNumber(value: unknown): value is number {
  return typeof value === `number`
}

export function getDisplayUserName(user: User | undefined, lastInitial: boolean): string {
  if (user == null) return i18n.t(`Deleted user`)
  const { first_name, last_name } = user
  if (first_name && last_name) return first_name + ` ` + (lastInitial ? last_name?.charAt(0) : last_name)
  else if (first_name) return first_name
  else return ``
}

export function getBulletinDisplayUserName(name: string | null): string {
  if (name == null || name === ``) return i18n.t(`Deleted user`)
  else return name
}

export function round(value: number, precision: number): number {
  if (typeof value !== `number`) {
    return value
  }

  const multiplier = Math.pow(10, precision)
  return Math.round(value * multiplier) / multiplier
}

export function toDecimals(value: number | string, decimals?: number, fallbackDecimals = 2): string {
  if (typeof value === `string`) {
    return value
  }

  if (decimals === undefined || decimals === null || typeof decimals !== 'number' || decimals > 10) {
    return Number(value).toFixed(fallbackDecimals)
  }

  return Number(value).toFixed(decimals)
}

export function clone<T>(data: T): T {
  if (!data) {
    return data
  }

  return JSON.parse(JSON.stringify(data))
}

export function removeFalsyValues(obj: Record<string, unknown>): Record<string, unknown> {
  const newObj = clone(obj)

  Object.keys(newObj)
    .filter((key) => newObj[key] === undefined || newObj[key] === null)
    .forEach((key) => delete newObj[key])

  return newObj
}

export function isEqual(a: unknown, b: unknown): boolean {
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime()
  }
  if (isObject(a) && isObject(b)) {
    const allKeys = Array.from(new Set([...Object.keys(a), ...Object.keys(b)]))
    return allKeys.every((key) => isEqual(a[key], b[key]))
  }

  if (isArray(a) && isArray(b)) {
    if (a.length !== b.length) {
      return false
    }

    return a.every((item, index) => isEqual(item, b[index]))
  }

  const strictlyEqualTypes = new Set(['string', 'number', 'boolean'])
  if (strictlyEqualTypes.has(typeof a) && strictlyEqualTypes.has(typeof b)) {
    return a === b
  }

  if (a === null && b === null) {
    return true
  }

  if (a === undefined && b === undefined) {
    return true
  }

  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(value: any): value is Record<string, any> {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArray(value: any): value is Array<any> {
  return value && typeof value === `object` && value.constructor === Array
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyToNestedString<T>(item: any, applier: (str: string) => string | number | null): T {
  if (typeof item === `string`) {
    item = applier(item)
  } else if (isObject(item)) {
    Object.entries(item).forEach(([key, value]) => {
      item[key] = applyToNestedString(value, applier)
    })
  } else if (isArray(item)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item.forEach((value: any, index: number) => {
      item[index] = applyToNestedString(value, applier)
    })
  }

  return item
}

export function applyToNestedKeysThatStartWith<T>(item: T, startsWith: string, applier: (str: string) => string): T {
  if (isArray(item)) {
    return (item as Array<unknown>).map((item) => applyToNestedKeysThatStartWith(item, startsWith, applier)) as T
  }

  if (!isObject(item)) {
    return item
  }

  const newItem: Record<string, unknown> = {}
  Object.entries(item as Record<string, unknown>).forEach(([key, value]) => {
    if (key.startsWith(startsWith)) {
      newItem[applier(key)] = value
    } else {
      newItem[key] = applyToNestedKeysThatStartWith(value, startsWith, applier)
    }
  })

  return newItem as T
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyToNestedStringForDictKeys<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any,
  dictKeys: Set<string>,
  applier: (str: string) => string | number
): T {
  if (isObject(item)) {
    Object.entries(item).forEach(([key, value]) => {
      if (dictKeys.has(key) && typeof value === 'string') {
        item[key] = applier(value)
      } else if (dictKeys.has(key) && isArray(value)) {
        item[key] = item[key].map((item) => {
          if (typeof item === 'string') {
            return applier(item)
          }

          return applyToNestedStringForDictKeys(item, dictKeys, applier)
        })
      } else {
        item[key] = applyToNestedStringForDictKeys(value, dictKeys, applier)
      }
    })
  } else if (isArray(item)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item.forEach((value: any, index: number) => {
      item[index] = applyToNestedStringForDictKeys(value, dictKeys, applier)
    })
  }

  return item
}

export function useHasPermission(permission: UserPermission): boolean {
  const snap = useSnapshot(authStore)
  if (snap.isSuperUser) {
    return true
  }
  return snap.permissions.has(permission)
}

export function useHasGlobalPermission(global_permissions: UserRoles | string): boolean | undefined {
  const snap = useSnapshot(authStore)
  if (snap.isSuperUser) {
    return true
  }
  return snap.user?.global_permissions.includes({ permission: global_permissions })
}

export function useHasAnchorComponent(uiConfigComponent: string): boolean {
  const editingUiConfigSnap = useSnapshot(editingUiConfigStore)
  let hasPublishedAnchorComponent = false

  Object.entries(editingUiConfigSnap.uiConfigs).forEach(([_uid, uiConfig]) => {
    if (uiConfig.component === uiConfigComponent) {
      if (uiConfig.is_published) {
        hasPublishedAnchorComponent = true
      }
    }
  })

  return hasPublishedAnchorComponent
}

export function merge<U, V>(original: U, overrider: V): U | V {
  if (isArray(original) && isArray(overrider)) {
    return clone(overrider)
  }

  if (isObject(original) && isObject(overrider)) {
    const merged: Record<string, unknown> = {}

    // Override all values in original with overrider
    Object.entries(original)
      .filter(([key]) => key in overrider)
      .forEach(([key, value]) => {
        merged[key] = merge(value, overrider[key])
      })

    // Keep original values if not in overrider
    Object.entries(original)
      .filter(([key]) => !(key in overrider))
      .forEach(([key, value]) => {
        merged[key] = clone(value)
      })

    // Extend original with overrider
    Object.entries(overrider)
      .filter(([key]) => !(key in original))
      .forEach(([key, value]) => {
        merged[key] = clone(value)
      })

    return merged as U | V
  }

  return overrider
}

export function sortArrayOfDicts<T extends Record<string, unknown>>(array: T[], key: keyof T, ascending = true): T[] {
  const sortedArray = array.sort((a, b) => {
    if (key in a && key in b) {
      return a[key] > b[key] ? 1 : -1
    }

    if (key in a) {
      return -1
    }

    if (key in b) {
      return 1
    }

    return 0
  })

  if (!ascending) {
    return sortedArray.reverse()
  }

  return sortedArray
}

export function sortArrayOfDictsWithMultipleKeys<T extends Record<string, unknown>>(
  array: T[],
  keys: Array<keyof T>,
  ascending = true
): T[] {
  /*
  array = [{ y: 3 }, { x: 2, y: 32 }, { y: 31 }, { x: 3, y: 3 }]
  keys = ['x', 'y']
  ascending = true

  return [{ x: 2, y: 32 }, { x: 3, y: 3 }, { y: 3 }, { y: 31 }]
  */
  const sortedArray = array.sort((a, b) => {
    let result = 0

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]

      if (key in a && key in b) {
        if (a[key] > b[key]) {
          result = 1
          break
        } else if (a[key] < b[key]) {
          result = -1
          break
        }
      } else if (key in a) {
        result = -1
        break
      } else if (key in b) {
        result = 1
        break
      }
    }

    return result
  })

  if (!ascending) {
    return sortedArray.reverse()
  }

  return sortedArray
}

export function hexToRgbA(hexCode: string, opacity?: number): string {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexCode)) {
    c = hexCode.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(Number(c) >> 16) & 255, (Number(c) >> 8) & 255, Number(c) & 255].join(',') +
      ',' +
      (opacity ?? 1) +
      ')'
    )
  }
  throw new Error('Bad hexcode when converting to rgba')
}
