import { clearLocalStorage } from 'api/api.utils'
import bugsnag from 'utils/bugsnag/bugsnag'

import { queryClient } from 'helpers/queryClient'
const baseUrl = process.env.REACT_APP_API_URL ?? `http://localhost:8000/api`
export function getUserFromLocalStorage(): User | null {
  const userItem = localStorage.getItem(`user`)
  const user = userItem ? JSON.parse(userItem) : null

  return user
}

function formatParams(params: Params, addBracketsToArrayKeys = true): [string, string][] {
  return Object.entries(params).reduce<[string, string][]>((acc, [key, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        const arrayParams = value.map((arrayValue) => {
          // `addBracketsToArrayKeys` is used to add brackets to array query params (`?key[]=val1&key[]=val2`).
          const qpKey = addBracketsToArrayKeys ? `${key}[]` : key
          return [qpKey, arrayValue.toString()] as [string, string]
        })
        return [...acc, ...arrayParams]
      }
      return [...acc, [key, value.toString()]]
    }
    return acc
  }, [])
}

type ParamType = string | number | boolean
type Param = ParamType | ParamType[] | undefined
type Params = Record<string, Param>

type Config = RequestInit & {
  method?: `GET` | `POST` | `PATCH` | `PUT` | `DELETE`
  params?: Params
  data?: Record<string, unknown>
  ignoreTrailingBackslash?: boolean
  ignore404?: boolean
  addBracketsToArrayParamKeys?: boolean
}

export async function apiClient<ResponseType>(
  endpoint: string,
  { params, data, headers, addBracketsToArrayParamKeys, ...overrides }: Config = {}
): Promise<ResponseType> {
  const user = getUserFromLocalStorage()
  const language = localStorage.getItem('language')
  const config = {
    method: data ? `POST` : `GET`,
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...(user ? { Authorization: `Token ${user.token}` } : undefined),
      ...(language ? { 'Accept-Language': `${language}` } : undefined),
      ...(data ? { 'Content-Type': `application/json` } : undefined),
      ...headers,
    },
    ...overrides,
  }

  let url = overrides?.ignoreTrailingBackslash ? `${baseUrl}/${endpoint}` : `${baseUrl}/${endpoint}/`

  if (params) {
    url += `?${new URLSearchParams(formatParams(
      params,
      addBracketsToArrayParamKeys === undefined || addBracketsToArrayParamKeys
    ))}`
  }

  return fetch(url, config).then(
    async (response) => {
      if (overrides?.ignore404 && response.status === 404) {
        return null
      }

      const contentType = response.headers.get(`content-type`)
      const contentLength = response.headers.get(`content-length`)

      let result
      if (contentType?.indexOf(`application/json`) !== -1 && contentLength && parseInt(contentLength) > 0) {
        result = response.json()
      } else {
        result = response.text()
      }
      if (response.status === 401) {
        clearLocalStorage()
        sessionStorage.clear()
        queryClient.clear()
        window.location.replace(`/`)
        return Promise.reject(await result)
      }

      if (response.ok) {
        return await result
      } else {
        return Promise.reject(await result)
      }
    },
    (error) => {
      bugsnag.notify(error)
      return Promise.reject(error)
    }
  )
}

// Own ApiClient for SettlementsExcelExport
export type SpreadsheetResult = { result?: ArrayBuffer; filename?: string }

export async function spreadsheetApiClient(
  endpoint: string,
  { params, data, headers, ...overrides }: Config = {}
): Promise<SpreadsheetResult> {
  const user = getUserFromLocalStorage()
  const config = {
    method: `GET`,
    headers: {
      ...(user ? { Authorization: `Token ${user.token}` } : undefined),
      ...(data ? { 'Content-Type': `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` } : undefined),
      ...headers,
    },
    ...overrides,
  }

  let url = `${baseUrl}/${endpoint}/`

  if (params) {
    url += `?${new URLSearchParams(formatParams(params))}`
  }

  return fetch(url, config).then(
    async (response) => {
      const contentDisposition = response.headers.get(`content-disposition`)
      const filename = contentDisposition
        ?.split(`;`)
        .find((n: string) => n.includes(`filename=`))
        ?.replace(`filename=`, ``)
        .trim()

      let result
      const contentType = response.headers.get(`content-type`)
      if (contentType?.indexOf(`application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`) !== -1) {
        result = response.arrayBuffer()
      }

      if (response.status === 401) {
        clearLocalStorage()
        sessionStorage.clear()
        queryClient.clear()
        window.location.replace(`/`)
        return Promise.reject({
          result: await result,
          filename: ``,
        })
      }

      if (response.ok) {
        return {
          result: await result,
          filename: filename,
        }
      } else {
        return Promise.reject({
          result: await result,
          filename: ``,
        })
      }
    },
    (error) => {
      bugsnag.notify(error)
      return Promise.reject(error)
    }
  )
}
