import React, { ReactElement, Suspense, useMemo } from 'react'

import { useModules } from 'api/modules/modules.api'
import { useUiConfig } from 'api/uiConfig/uiConfig.api'
import { ErrorBoundary } from 'react-error-boundary'
import authStore from 'store/auth/auth'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import Alert from 'ui/components/Alert/Alert'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import ErrorFallback from 'ui/components/ErrorFallback/ErrorFallback'
import Navigation from 'ui/components/Navigation/Navigation'
import PrivateSwitch from 'ui/components/PrivateSwitch/PrivateSwitch'
import StageBanner from 'ui/components/StageBanner/StageBanner'
import bugsnag from 'utils/bugsnag/bugsnag'
import { useSnapshot } from 'valtio'

import { useMainRoutes } from 'routes/main.routes'

import Initialization from './Initialization'
import styles from './MainLayout.module.less'
import { filterRoutes } from './MainLayout.utils'

export default function MainLayout(): ReactElement {

  const { data: modules = [] } = useModules()
  const { alertClear, alert } = useAlert()
  const mainRoutes = useMainRoutes()

  const routes = useMemo(() => filterRoutes(mainRoutes, modules), [mainRoutes, modules])

  const authSnap = useSnapshot(authStore)
  useUiConfig(authSnap.systemId, `base_config`)

  return (
    <div className={styles.MainLayout_Root}>
      <StageBanner />
      <Navigation routes={routes}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => bugsnag.notify(error)}>
          <Suspense fallback={<LoadingPlaceholderContainer/>}>
            <PrivateSwitch routes={routes} />
          </Suspense>
        </ErrorBoundary>
        <Alert
          variant={alert?.variant ? alert.variant : `success`}
          message={alert?.message ? alert.message : ``}
          open={!!alert}
          onClose={alertClear}
          SnackbarProps={alert?.props}
        />
        <Initialization />
      </Navigation>
    </div>
  )
}

