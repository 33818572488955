import React, { ReactElement, useRef } from 'react'

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material'

import styles from './Tooltip.module.less'

/**
 * Wrapper for mui `Tooltip`. Use within `CardWidget` for tooltips to work in full screen.
 **/

type TooltipProps = {
  display?: 'block' | 'contents' | 'inline-block'
}

export default function Tooltip({ children, display, ...props }: TooltipProps & MuiTooltipProps): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null)
  const style: Record<string, string> = {}

  if (display) {
    style.display = display
  }

  return (
    <div ref={ref} className={styles.Tooltip} style={style}>
      <MuiTooltip PopperProps={{ container: ref.current }} {...props}>
        {children}
      </MuiTooltip>
    </div>
  )
}
